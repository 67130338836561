import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'

// 导入时间插件momentjs
import moment from 'moment'

// 定义时间格式全局过滤器
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  data:{
    shopData:[]
  }
}).$mount('#app')
