import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views'),
  },
  {
    path: '/profit',
    component: () => import('@/views/profit'),
  },
  {
    path: '/profit/doc',
    component: () => import('@/views/profit/doc')
  },
  {
    path: '/map',
    component: () => import('@/views/map')
  },
  {
    path: '/shop',
    component: () => import('@/views/shop')
  },
  {
    path: '/sales',
    component: () => import('@/views/sales/index')
  },
  {
    path: '/visa',
    component: () => import('@/views/visa/index')
  }
]
const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
export default router
