<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app {
  max-width: 1280px;
  min-width: 1280px;
  margin: 0 auto;
}
.el-header {
  border-bottom: 1px solid #e1e1e1;
}
</style>
